<template>
  <div id="App">
    <Carousel :texta="false" :height="height" :imgList="imgList" />
    <div class="news">
      <div class="title">
        <img src="@/assets/yushouke.png" alt="" />
      </div>
      <div class="text">
        易手客是专注于大学生二手交易的平台,在上面,你可以找到学生所需的生活用品、数码电器、房屋转租、书籍资料等等,是一个很方便生活的平台。在毕业季,希望学生们的二手物品都能找到新的归宿。我们希望这样一个平台能让所有学生学会如何运用并喜欢上它,就像支付宝一样方便快捷,帮助在校学生解决实际问题。为学生们打开一扇新的大门!
        联系我们 联系邮箱 E - mail :2721999296@qq.com
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  //数据
  data() {
    return {
      height: "600px",
      imgList: [
        require("@/assets/back.jpg"),
        require("@/assets/bigimg.jpg"),
        require("@/assets/bigimgC.jpg"),
      ],
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang='scss'>
#App {
  width: 100%;

  .news {
    width: 62%;
    margin: auto;
    .title {
      display: flex;
      justify-content: center;
      img {
        margin: auto;
        width: 200px;
      }
    }
    .text {
      font-size: 18px;
      margin-bottom: 40px;
      line-height: 30px;
    }
  }
}
</style>
